import classNames from 'classnames';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import type { RootState } from '../../redux/slice';
import { getFormData } from '../../redux/slice/formData';
import { isWhiteLabelMode } from '../../redux/slice/ui';
import ContactTelephoneNumber from './ContactTelephoneNumber';
import ContractorLicense from './ContractorLicense';
import LogoHeaderItem from './LogoHeaderItem';

type InstantQuoteHeaderOwnProps = {
  className?: string;
  showResidence?: boolean;
};

function mapStateToProps(state: RootState) {
  const { lastName, streetAddress, city, state: stateCode, zipCode } = getFormData(state);
  const addrLine2 = stateCode ? `${city}, ${stateCode} ${zipCode}` : `Zip ${zipCode}`;
  return {
    isWhiteLabelMode: isWhiteLabelMode(state),
    lastName,
    streetAddress,
    addrLine2,
  };
}

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

type InstantQuoteHeaderProps = PropsFromRedux & InstantQuoteHeaderOwnProps;

class InstantQuoteHeader extends React.PureComponent<InstantQuoteHeaderProps> {
  render() {
    const { className, isWhiteLabelMode, lastName, streetAddress, addrLine2, showResidence } = this.props;

    return (
      <div
        className={classNames(
          'InstantQuoteHeader pb-4 d-flex justify-content-md-center justify-content-between align-items-end',
          className,
        )}
      >
        <LogoHeaderItem small className="d-md-none" />
        <div className="d-flex flex-column">
          {isWhiteLabelMode && (
            <>
              <ContractorLicense className="d-md-none text-muted text-end" />
              <ContactTelephoneNumber className="d-md-none text-muted text-end" />
            </>
          )}
          {lastName && streetAddress && addrLine2 && showResidence ? (
            <div className="fs-5 text-end text-md-center">
              <div>{lastName} Residence</div>
              <div>{streetAddress}</div>
              <div>{addrLine2}</div>
            </div>
          ) : (
            <div className="fs-5 text-end text-md-center">Get Your Instant Quote</div>
          )}
        </div>
      </div>
    );
  }
}

export default connector(InstantQuoteHeader);
