import { DebouncedFunc } from 'lodash-es';
import type { CheckAndUpdateArgs, FormFields, PlaceData } from '../typedefs';
import AutoCompleteUtil from './autoCompleteUtil';
import TrackingUtil, { SendFormEventFunction } from './trackingUtil';

type FieldFunctions = {
  updateField: (args: { field: keyof FormFields; value: any }) => void;
  checkAndUpdateProject(args?: { keepProjectData?: boolean }): Promise<any>;
};

type AugmentedCheckAndUpdateArgs = CheckAndUpdateArgs & {
  // postChangeAction is always called after updateField and before checkAndUpdateProject.
  postChangeAction?: (field: keyof FormFields, value: string) => Promise<void>;
};

async function handleUpdate(
  field: keyof FormFields,
  value: string,
  props: FieldFunctions,
  updateArgs?: AugmentedCheckAndUpdateArgs,
  debouncedGAEvent?: DebouncedFunc<SendFormEventFunction>,
) {
  const { updateField, checkAndUpdateProject } = props;
  updateField({ field, value });
  if (value.length > 1 && debouncedGAEvent) {
    debouncedGAEvent(field, value);
  }

  const { postChangeAction, ...restOfUpdateArgs } = updateArgs || {};

  if (postChangeAction) {
    await postChangeAction(field, value);
  }

  checkAndUpdateProject(restOfUpdateArgs);
}

const FormUtil = {
  createFieldHandler(field: keyof FormFields, props: FieldFunctions, updateArgs?: AugmentedCheckAndUpdateArgs) {
    const debouncedGAEvent = TrackingUtil.createDebouncedFormUpdateFunc();
    return (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      e.preventDefault();
      const { value } = e.currentTarget;
      handleUpdate(field, value, props, updateArgs, debouncedGAEvent);
    };
  },

  ignoreFormSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
  },

  createOnChangeHandler(field: keyof FormFields, props: FieldFunctions, updateArgs?: AugmentedCheckAndUpdateArgs) {
    const debouncedGAEvent = TrackingUtil.createDebouncedFormUpdateFunc();
    return (value: string) => {
      handleUpdate(field, value, props, updateArgs, debouncedGAEvent);
    };
  },

  createAddressAutocompleteChangedHandler(
    props: FieldFunctions & {
      fetchPropertyData(): void;
      setPlaceData(placeData: PlaceData): void;
    },
    updateArgs?: AugmentedCheckAndUpdateArgs,
  ) {
    const debouncedGAEvent = TrackingUtil.createDebouncedFormUpdateFunc();
    return (placeData: PlaceData) => {
      TrackingUtil.addressVerify();
      props.setPlaceData(placeData);

      const streetAddr = AutoCompleteUtil.getStreetAddress(placeData);
      if (streetAddr) {
        handleUpdate('streetAddress', streetAddr, props, updateArgs, debouncedGAEvent);
      }

      const { zipCode } = placeData;
      if (zipCode) {
        handleUpdate('zipCode', zipCode, props, updateArgs);
      }

      if (streetAddr && zipCode) {
        props.fetchPropertyData();
      }
    };
  },

  createNestedFieldHandler(
    field: string,
    props: { updateNestedField: (args: { field: string; value: any }) => void; checkAndUpdateProject(): void },
  ) {
    const debouncedGAEvent = TrackingUtil.createDebouncedFormUpdateFunc();
    return (e: React.FormEvent<HTMLInputElement>) => {
      const { updateNestedField, checkAndUpdateProject } = props;
      const { value } = e.currentTarget;
      updateNestedField({ field, value });
      if (value.length > 1) {
        debouncedGAEvent(field, value);
      }
      checkAndUpdateProject();
    };
  },
};

export default FormUtil;
