import classNames from 'classnames';
import { isEmpty, map } from 'lodash-es';
import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import type { RootState } from '../../redux/slice';
import { getGeneralErrors } from '../../redux/slice/errors';
import { FormErrors } from '../../typedefs';
import StringUtil from '../../utils/stringUtil';

type GeneralErrorsOwnProps = {
  className?: string;
  errors?: FormErrors;
};

function mapStateToProps(store: RootState, ownProps: GeneralErrorsOwnProps) {
  return {
    generalErrors: ownProps.errors?.generalErrors || getGeneralErrors(store),
  };
}

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type GeneralErrorsProps = PropsFromRedux & GeneralErrorsOwnProps;

class GeneralErrors extends React.PureComponent<GeneralErrorsProps> {
  render() {
    const { generalErrors, className } = this.props;
    return (
      !isEmpty(generalErrors) &&
      map(generalErrors, (error) => (
        <div
          className={classNames('alert alert-danger is-invalid', className)}
          role="alert"
          key={StringUtil.hash(error)}
        >
          {error}
        </div>
      ))
    );
  }
}

export default connector(GeneralErrors);
