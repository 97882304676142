import classNames from 'classnames';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import type { RootState } from '../../redux/slice';
import { getStepOverrides, isWhiteLabelMode } from '../../redux/slice/ui';
import { StepName } from '../../typedefs';
import ContactTelephoneNumber from './ContactTelephoneNumber';
import ContactorLicense from './ContractorLicense';
import Image from './Image';
import LogoHeaderItem from './LogoHeaderItem';

type HeroPanelOwnProps = {
  heroImageUrl: string;
  heroImageAlt: string;
  partnerQuote: React.ReactElement;
  edenQuote: React.ReactElement;
  stepName: StepName;
  imageWidth: number;
  mobile?: boolean;
};

function mapStateToProps(state: RootState, ownProps: HeroPanelOwnProps) {
  return {
    isWhiteLabelMode: isWhiteLabelMode(state),
    overrides: getStepOverrides(state, ownProps.stepName),
  };
}

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type HeroPanelProps = HeroPanelOwnProps & PropsFromRedux;

class HeroPanel extends React.PureComponent<HeroPanelProps> {
  renderImage() {
    const { overrides, heroImageAlt, heroImageUrl, imageWidth } = this.props;
    const { photoAlt, photoUrl } = overrides;
    if (photoAlt && photoUrl) {
      return <Image src={photoUrl} alt={photoAlt} sizes={`${imageWidth}vw`} fill priority />;
    }
    return <Image src={heroImageUrl} alt={heroImageAlt} sizes={`${imageWidth}vw`} fill priority />;
  }

  renderQuote() {
    const { isWhiteLabelMode, partnerQuote, edenQuote, overrides } = this.props;
    const { title, body } = overrides;
    if (!isWhiteLabelMode) {
      return edenQuote;
    }
    if (title || body) {
      return (
        <>
          {title && <h2>{title}</h2>}
          {body && <div className="quote fs-5">{body}</div>}
        </>
      );
    }
    return partnerQuote;
  }

  render() {
    const { isWhiteLabelMode, stepName, mobile } = this.props;
    return (
      <div className={classNames(`${stepName} HeroPanel pt-2`, { partner: isWhiteLabelMode })}>
        {!mobile && (
          <div
            className={classNames('header d-flex justify-content-between px-3', {
              'pt-5': !isWhiteLabelMode,
              'pt-3 pb-3': isWhiteLabelMode,
            })}
          >
            <LogoHeaderItem />
            {isWhiteLabelMode && (
              <div className="text-end">
                <ContactorLicense className="text-muted" />
                <ContactTelephoneNumber className="text-muted" />
              </div>
            )}
          </div>
        )}
        <div className="Image">
          <div className="Accent"></div>
          {this.renderImage()}
          <div className="PullQuote">
            <div className="quoteContainer">{this.renderQuote()}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default connector(HeroPanel);
