type ExternalLinkProps = {
  href: string;
  children: React.ReactNode;
  className?: string;
};

export default function ExternalLink(props: ExternalLinkProps) {
  const { href, children, className } = props;
  return (
    <a href={href} target="_blank" rel="noreferrer" className={className}>
      {children}
    </a>
  );
}
