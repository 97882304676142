type PanelProps = {
  leftPanel: React.ReactElement;
  rightPanel: React.ReactElement;
};

export default function Panel(props: PanelProps) {
  const { leftPanel, rightPanel } = props;
  return (
    <div className="Panels d-flex">
      <div className="topBar d-none d-md-block"></div>
      <div className="LeftPanel d-none d-md-block col-md-6 col-lg-7">{leftPanel}</div>
      <div className="RightPanel d-flex flex-column flex-nowrap col-12 offset-md-6 offset-lg-7 col-md-6 col-lg-5">
        {rightPanel}
      </div>
    </div>
  );
}
