import classnames from 'classnames';
import { map } from 'lodash-es';
import React from 'react';
import StringUtil from '../../utils/stringUtil';

type ElementWrapperProps = {
  name: string;
  label?: string;
  className?: string;
  description?: string | React.ReactNode;
  compact?: boolean;
  specificFieldErrors?: string[];
  children?: React.ReactNode;
  inputFirst?: boolean;
  descriptionBelowInput?: boolean;
};

const ElementWrapper: React.FC<ElementWrapperProps> = ({
  name,
  className,
  description,
  label,
  compact,
  specificFieldErrors,
  children,
  inputFirst,
  descriptionBelowInput,
}) => {
  const spacing = compact ? 'mb-1' : 'mb-4';

  const descriptionElement =
    typeof description === 'string' ? <div className="text-muted">{description}</div> : description;

  return (
    <div className={classnames('form-group', spacing, className)}>
      {inputFirst && children}
      {label && <label htmlFor={name}>{label}</label>}
      {!descriptionBelowInput && descriptionElement}
      {!inputFirst && children}
      {descriptionBelowInput && descriptionElement}
      {map(specificFieldErrors, (error) => (
        <div className="invalid-feedback" key={StringUtil.hash(error)}>
          {error}
        </div>
      ))}
    </div>
  );
};

export default ElementWrapper;
