import React from 'react';
import { StepValidator } from '../../typedefs';
import { StepType } from '../../utils/enums';
import GeneralErrors from './GeneralErrors';
import InstantQuoteHeader from './InstantQuoteHeader';
import WizardNav from './WizardNav';

type StepLayoutProps = {
  className?: string;
  mobileHero?: React.ReactElement;
  children: React.ReactNode;
  currentStepName: StepType;
  showResidence?: boolean;
  stepValidation: StepValidator | null;
};

export default function StepLayout(props: StepLayoutProps) {
  const { className, children, currentStepName, stepValidation, mobileHero, showResidence } = props;
  return (
    <>
      <InstantQuoteHeader showResidence={showResidence} />
      {!!mobileHero && <div className="d-block d-md-none">{mobileHero}</div>}
      <GeneralErrors className="d-md-none mt-3" />
      <div className={`StepLayout h-100 mt-4 mt-md-0 ${className}`}>{children}</div>
      <WizardNav currentStepName={currentStepName} stepValidation={stepValidation} />
    </>
  );
}
