import Image, { ObjectFit } from './Image';

type InstallerLogoImageProps = {
  src: string;
  size: { width: number; height: number } | { objectFit: ObjectFit };
  name?: string;
  partnerSlug?: string;
  className?: string;
};

export default function InstallerLogoImage(props: InstallerLogoImageProps) {
  const { src, name, partnerSlug, size, ...rest } = props;
  const altText = name || partnerSlug || ' logo';
  return (
    <Image
      src={src}
      alt={altText}
      {...size}
      fill={Object.hasOwn(size, 'objectFit')}
      sizes={Object.hasOwn(size, 'objectFit') ? '100px' : undefined}
      {...rest}
    />
  );
}
