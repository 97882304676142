import { head } from 'lodash-es';
import { PlaceData } from '../typedefs';
import { FEET_PER_METER } from './constants';

type PlaceAddressField =
  | 'street_number'
  | 'route'
  | 'neighborhood'
  | 'locality'
  | 'administrative_area_level_2'
  | 'administrative_area_level_1'
  | 'country'
  | 'postal_code'
  | 'postal_code_suffix'
  | 'subpremise';

function getPlaceField(result: google.maps.places.PlaceResult, fieldName: PlaceAddressField, longName?: boolean) {
  const ret = result.address_components?.filter((val) => val.types.includes(fieldName));
  if (!ret || !ret.length) return undefined;
  const val = ret[0];
  return longName ? val.long_name : val.short_name;
}

function getZipCode(result: google.maps.places.PlaceResult) {
  return getPlaceField(result, 'postal_code', true);
}

function getZipCodeSuffix(result: google.maps.places.PlaceResult) {
  return getPlaceField(result, 'postal_code_suffix', true);
}

function getCity(result: google.maps.places.PlaceResult) {
  return getPlaceField(result, 'locality');
}

function getState(result: google.maps.places.PlaceResult) {
  return getPlaceField(result, 'administrative_area_level_1');
}

function getCounty(result: google.maps.places.PlaceResult) {
  return getPlaceField(result, 'administrative_area_level_2');
}

function getSubpremise(result: google.maps.places.PlaceResult) {
  const subpremise = getPlaceField(result, 'subpremise');
  if (subpremise && !isNaN(parseInt(subpremise))) {
    return `#${subpremise}`;
  }
  return subpremise;
}

function getCountry(result: google.maps.places.PlaceResult) {
  const country = getPlaceField(result, 'country');
  if (country === 'US') {
    // Google uses USA in the input field, but doesn't provide it as a result field.
    return 'USA';
  }
  return country;
}

function getStreetAddress(address: PlaceData): string | undefined {
  const { streetNumber, route, subpremise } = address;
  const subpremiseStr = subpremise !== undefined ? ` ${subpremise}` : '';
  if (streetNumber && route) {
    return `${streetNumber} ${route}${subpremiseStr}`;
  }
  return undefined;
}

function getFullAddress(address: PlaceData) {
  const streetAddr = getStreetAddress(address);
  const { city, state, country } = address;

  if (streetAddr && city && state && country) {
    return `${streetAddr}, ${city}, ${state}, ${country}`;
  }
}

function getPlaceData(result: google.maps.places.PlaceResult): PlaceData {
  return {
    streetNumber: getPlaceField(result, 'street_number', true),
    subpremise: getSubpremise(result),
    route: getPlaceField(result, 'route', true),
    city: getCity(result),
    county: getCounty(result),
    state: getState(result),
    country: getCountry(result),
    neighborhood: getPlaceField(result, 'neighborhood'),
    zipCode: getZipCode(result),
    zipSuffix: getZipCodeSuffix(result),
  };
}

function getLatLong(result: google.maps.places.PlaceResult): { lat: number; lng: number } | undefined {
  const { location } = result.geometry || {};
  const { lat: latFunc, lng: lngFunc } = location || {};
  const lat = latFunc && latFunc();
  const lng = lngFunc && lngFunc();

  if (!lat || !lng) return undefined;

  return {
    lat,
    lng,
  };
}

async function retrieveElevationForPlace(result: google.maps.places.PlaceResult) {
  const latLng = getLatLong(result);

  if (latLng) {
    const data = await google.maps.ElevationService.prototype.getElevationForLocations({
      locations: [latLng],
    });

    const elevResult = head(data.results);
    const { elevation: elevationInM } = elevResult || {};

    if (elevationInM !== undefined) {
      return Math.round(elevationInM * FEET_PER_METER);
    }
  }

  return undefined;
}

const AutoCompleteUtil = {
  getStreetAddress,
  getFullAddress,
  getPlaceData,
  getLatLong,
  retrieveElevationForPlace,
};

export default AutoCompleteUtil;
