import classNames from 'classnames';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import type { RootState } from '../../redux/slice';
import { getPartnerSettings, getUiStore } from '../../redux/slice/ui';
import { BASE_E_DEN_URL, E_DEN_SHORT_NAME } from '../../utils/constants';
import ExternalLink from './ExternalLink';

type PoweredByEDENOwnProps = {
  className?: string;
};

function mapStateToProps(state: RootState) {
  const ui = getUiStore(state);
  const { partnerDataLoaded } = ui;
  const partnerSettings = getPartnerSettings(state);
  const { showPoweredByEden } = partnerSettings;
  return {
    partnerDataLoaded,
    showPoweredByEden,
  };
}

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

type PoweredByEDENProps = PropsFromRedux & PoweredByEDENOwnProps;

class PoweredByEDEN extends React.PureComponent<PoweredByEDENProps> {
  render() {
    const { partnerDataLoaded, showPoweredByEden, className } = this.props;
    if (!partnerDataLoaded || !showPoweredByEden) {
      return null;
    }

    return (
      <div className={classNames('PoweredByEDEN text-muted', className)}>
        Powered by{' '}
        <ExternalLink href={BASE_E_DEN_URL} className="eco">
          {E_DEN_SHORT_NAME}
        </ExternalLink>
      </div>
    );
  }
}

export default connector(PoweredByEDEN);
