import Head from 'next/head';
import { getPartnerSettings } from '../redux/slice/ui';
import { useAppSelector } from '../redux/store';
import type { PartnerSettings } from '../typedefs';
import { EDEN_LOGO, IMAGE_FOLDER, STEP_NAMES } from '../utils/constants';
import ErrorUtil from '../utils/errorUtil';
import PathUtil from '../utils/pathUtil';
import WindowUtil from '../utils/windowUtil';

const validOgImageTypes = ['png', 'jpg', 'jpeg', 'gif'];

function hasValidOgImageType(logoUrl?: string) {
  if (!logoUrl) return false;
  return validOgImageTypes.some((type) => logoUrl.endsWith(type));
}

function getImage(partnerSlug?: string, logoUrl?: string): string {
  const validPartnerImage = hasValidOgImageType(logoUrl);
  if (logoUrl && !validPartnerImage) {
    ErrorUtil.logError(new Error(`Invalid og:image type for partner ${partnerSlug} with imageUrl ${logoUrl}`));
  }
  return validPartnerImage ? (logoUrl as string) : EDEN_LOGO;
}

function getContent(partnerSettings: Partial<PartnerSettings>, stepName: string) {
  const browserPath = WindowUtil.getLocationPath();
  const origin = WindowUtil.getLocationOrigin();
  const partnerSlug = PathUtil.getPartnerSlug(browserPath);
  const logoUrl = getImage(partnerSlug, partnerSettings.logoUrl);
  const partnerName = partnerSettings.name || 'EDEN';
  return {
    title: `Get Your Instant Quote - ${partnerName} - ${stepName}`,
    description:
      'Get an upfront quote for your new heating and cooling system. See rebates, savings and financing options in less than a minute.',
    image: `${IMAGE_FOLDER}${logoUrl}`,
    url: `${origin}/${partnerSlug}/${STEP_NAMES.INTRO}`,
    siteName: 'EDEN',
  };
}

export default function InstantQuotePageHead({ stepName }: { stepName: string }) {
  const partnerSettings = useAppSelector(getPartnerSettings);
  const { title, description, image, url, siteName } = getContent(partnerSettings, stepName);
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={image} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:locale" content="en_US" />
    </Head>
  );
}
