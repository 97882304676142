import { getPartnerSettings } from '../redux/slice/ui';
import { useAppSelector } from '../redux/store';
import ColorUtil from '../utils/colorUtil';

export default function StyleWrapper({ children }: { children: React.ReactNode }) {
  const { primaryColor, secondaryColor, accentColor } = useAppSelector(getPartnerSettings);

  const style = `:root {
    ${primaryColor && `--primary: ${primaryColor}; --primary-rgb: ${ColorUtil.toRGB(primaryColor)};`}
    ${secondaryColor && `--secondary: ${secondaryColor}; --secondary-rgb: ${ColorUtil.toRGB(secondaryColor)};`}
    ${accentColor && `--accent: ${accentColor};`}
  }

  ${
    primaryColor &&
    `.accordion-button:not(.collapsed)::after {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='${primaryColor}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
    }
    
    .accordion-button::after {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='${primaryColor}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
    }`
  }`;
  return (
    <div>
      <style
        // TODO this would allow an attacker to inject HTML via the primary color
        dangerouslySetInnerHTML={{
          __html: style,
        }}
      ></style>
      {children}
    </div>
  );
}
