import { useRouter } from 'next/router';
import React, { lazy, Suspense, useEffect } from 'react';
import { modalActions } from '../redux/slice/modal';
import { rebateActions } from '../redux/slice/rebates';
import { useAppDispatch } from '../redux/store';
import { initializeApp } from '../redux/thunks';
import { useEnableIncomeQualificationCriteriaIsOn } from '../utils/experiments';
import GeneralErrors from './common/GeneralErrors';
import Panel from './common/Panels';

const ModalDialog = lazy(() => import('./modals/ModalDialog'));

type InstantQuoteOwnProps = {
  leftPanel: React.ReactElement;
  rightPanel: React.ReactElement;
};

const { closeModal } = modalActions;

const generateRightPanel = (rightPanel: React.ReactElement) => {
  return (
    <div className="d-flex flex-grow-1 flex-column flex-nowrap">
      <GeneralErrors className="mt-4 mb-0 d-none d-md-block" />
      {rightPanel}
    </div>
  );
};

type InstantQuoteProps = InstantQuoteOwnProps;

const InstantQuote: React.FC<InstantQuoteProps> = ({ leftPanel, rightPanel }) => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const enableIncomeQualificationCriteria = useEnableIncomeQualificationCriteriaIsOn();

  useEffect(() => {
    dispatch(initializeApp(router));

    const handleRouteChange = () => {
      dispatch(closeModal());
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router, dispatch]);

  useEffect(() => {
    setTimeout(() => dispatch(rebateActions.setAllowIncomeQualificationCriteria(enableIncomeQualificationCriteria)), 1);
  }, [dispatch, enableIncomeQualificationCriteria]);

  return (
    <div className="InstantQuote">
      <Panel leftPanel={leftPanel} rightPanel={generateRightPanel(rightPanel)} />
      <Suspense>
        <ModalDialog />
      </Suspense>
    </div>
  );
};

export default InstantQuote;
