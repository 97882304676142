import React from 'react';
import FormUtil from '../../utils/formUtil';

type StepPanelProps = {
  header: string;
  children: React.ReactNode;
  infoBox?: React.ReactElement;
  headerRight?: React.ReactElement;
};

export default function StepPanel(props: StepPanelProps) {
  const { header, children, headerRight, infoBox } = props;
  return (
    <form className="form-area needs-validation" noValidate onSubmit={FormUtil.ignoreFormSubmit}>
      {infoBox}
      <div className="d-flex align-items-center justify-content-between mb-4">
        {header && <h2 className="StepHeader fw-bold mb-0">{header}</h2>}
        {headerRight}
      </div>
      {children}
    </form>
  );
}
