import React from 'react';
import { StepValidator } from '../../typedefs';
import { StepType } from '../../utils/enums';
import Footer from './Footer';

type WizardChildProps = {
  currentStepName: StepType;
  stepValidation: StepValidator | null;
};

const WizardNav: React.FunctionComponent<WizardChildProps> = (props: WizardChildProps) => {
  const { stepValidation, currentStepName } = props;

  return (
    <div className="WizardNav">
      <div className="w-100">
        <Footer currentStep={currentStepName} stepValidation={stepValidation} />
      </div>
    </div>
  );
};

export default WizardNav;
